html, body, #root {
 height: 100%;
 overflow-y: hidden;
}
.App {
  min-height: 100%;
  /*background-color: #EEF2F3*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form-control:focus{
  border-color:unset!important;
  box-shadow: none!important;
  border-bottom: .1rem solid #dfe5e8!important;
}

.fs-1-5{
  font-size: 1.5rem;
}

.react-datepicker__day, .react-datepicker__day-name{
  width: 2.7rem!important;
  line-height: 2.7rem!important;
}

.bg-cozie-red span.f-icon{
  position: absolute;
  right:35px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.white_text{
  color:#fff!important;
}
.orange{
  background-color:#FF5722!important;
  border-color:#FF5722!important;
}
.orange:focus{
  box-shadow: 0 0 0 0.25rem rgb(255 87 34 / 50%)!important;
}
.orange:hover{
  background-color:#FC7449!important;
}
.orange_outline{
  border-color:#FF5722!important;
  background-color:#fff!important;
  color:#FF5722!important;
}
.orange_outline:focus{
  box-shadow: 0 0 0 0.25rem rgb(255 87 34 / 50%)!important;
}
.orange_outline:hover{
  background-color:#FF5722!important;
  color:#fff!important;
}
.pages_container{
  height: 100%;
}
.container_centered{
  display: block;
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  transform: translateY(-50%);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.single_side{
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.double_side{
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.fs-150{
  font-size: 150px;
}
label.btn input[type="radio"],label.btn input[type="checkbox"]{
  display: none;
}
label.btn small.select{
  display: none;
}
label.btn:hover small.select{
  display: block;
}
.font-size-10{
  font-size: 10px!important;
}
.select-proposal button.confirm{
  top:9px;
  min-height: 1.2rem!important;
  width: auto!important;
  font-size: 12px!important;
}
.alert-fixed,.alert-fixed-modal {
  z-index: 50;
  margin: 0 15px;
}
.alert-fixed{
  right: 0;
}
.alert-fixed button{
  float: right;
}
.cozie--bottom-buttons-wrap-reversed div {
    background-color: #fff;
    border: 0.1rem solid #f78a31;
    color: #f78a31;
    border-radius: 2rem;
}
.cozie--bottom-buttons-wrap-reversed div:hover {
    background-color: #f78a31;
    color: #fff;
    cursor: pointer;
    border: 0.1rem solid #f78a31;
}
.cozie--bottom-buttons-wrap-reversed div:hover p {
    color: #fff;
}
.btn-close:focus{
  box-shadow: unset!important;
}