.page-login-box {
    font-family: Raleway, sans-serif;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 37px;
}
.page-login-box h1 {
    margin-top: 0;
}
.page-login-box h1 img {
    width: 188px;
    height: auto
}
.page-login-panel {
	width:100%;
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #fff;
    border-radius: 10px;
    padding: 25px 50px 25px 50px;
}
.page-login-panel input:-webkit-autofill,
.page-login-panel input:-webkit-autofill:focus,
.page-login-panel input:-webkit-autofill:hover {
    -webkit-text-fill-color: #474a4c;
    -webkit-box-shadow: 0 0 0 1000px #fff inset
}
.page-login-panel,
.page-login-panel .btn,
.page-login-panel .form-control {
    font-size: 16px
}
.page-login-panel .form-group {
    margin-bottom: 10px
}
.page-login-panel .form-control {
    height: 48px;
}