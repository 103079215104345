/*#header_nav{
	padding-left:0rem;
	padding-right:0rem;
}
#header-navbar-nav .nav-link{
	font-size: 1.2rem;
}
#header-navbar-nav .nav-link:not(.dropdown-toggle){
	color:white;
	border-radius: 0px;
}
#header-navbar-nav .nav-link:not(.active):not(.dropdown-toggle){
	background-color: #666666;
}
#header-navbar-nav .nav-link:not(:first-child):not(.dropdown-toggle){
	margin-left:5px;
}
#header-navbar-nav .nav-link.active{
	background-color: #007bff;
	color:#fff
}
#header-navbar-logo #logo{
	padding-left: 0px;
}
#header-navbar-logo h5{
	color: #fff;
}
.simple_channel .dropdown-toggle.nav-link::after{
	display: none;
}
.simple_channel #logo{
	pointer-events: none;
}
@media screen and (max-width: 768px) {
  	#header-navbar-nav .nav-link:not(.dropdown-toggle){
		color: rgba(0,0,0,.5);
	}
	#header-navbar-nav .nav-link:not(.active):not(.dropdown-toggle){
		background-color: unset;
	}
	#header-navbar-nav .nav-link:not(:first-child):not(.dropdown-toggle){
		margin-left:0px;
	}
	#header-navbar-nav .nav-link.active{
		background-color: unset;
		color:black;
	}
}*/
.navigation-senec-logo {
	display: block;
	width: 3.4rem;
	margin-right: 0.5rem;
	height: 4.9rem;
	text-indent: -9999px;
	float: left;
	background: url(../../assets/images/logo-senec.png) no-repeat 50% / contain;
}
@media (min-width: 768px) {
    .navigation-senec-logo {
        width: 6rem;
    }
}
@media (min-width: 1024px) {
    .navigation-senec-logo {
        width: 10.2rem;
    }
}
@media (max-width: 767.98px) {
    .navigation-senec-logo {
        width: 10.2rem;
    }
}
@media (max-width: 767.98px) {
    .navigation-senec-logo {
        background-size: 8rem 3.9rem;
    }
}